
import rowLeft from "../assets/icon/Done Lime Green.svg";

const PhotoOdometroMensual = ({carPhoto, title, subtitle, poliza, onHandlePhotoButton, titleButton='Tomar fotografía', children}) => {
  return (
      <div className=" max-w-md w-full my-4  mx-auto">
        
        {
          children
        }
       
        <div className={` ${ carPhoto && carPhoto.startsWith("data:image/") && carPhoto.includes("base64,") ? 'hidden' : 'w-full px-4'} ` }>
          <p className=" text-sm text-gray-700 my-2">
          Imagen de ejemplo:
          </p>
        </div>
        <div className={` mx-4 ${ carPhoto && carPhoto.startsWith("data:image/") && carPhoto.includes("base64,") ? 'block' : 'hidden'} ` }>
          <div className="flex items-center gap-2 ">
              <div>
                  <img
                    src={rowLeft}
                    alt="card-image"
                    className="h-auto w-62 rounded-lg mx-auto"
                  />
              </div>
              <p className="text-sm text-liverpoolCarbon">Tu fotografía se registró correctamente.</p>
          </div>
        </div>
        
        <div className="mx-4 mt-4 overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl">
          <img
            src={carPhoto}
            alt="card-image"
            className="h-auto w-62 rounded-lg mx-auto"
          />
        </div>
        <div className={` ${onHandlePhotoButton ? "p-3 pt-0" : "hidden"}  `}>
          <button
            className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
            type="button"
            onClick={onHandlePhotoButton}
          >
            {titleButton}
          </button>
        </div>
    </div>
  );

}

export default PhotoOdometroMensual