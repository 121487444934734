export default async function checkOnlineStatus(callback) {
  // Función para hacer una solicitud a un servidor conocido
  const verifyConnection = async () => {
    try {
      // Realiza una solicitud GET a un servidor conocido (puedes cambiar la URL)
      const response = await fetch("https://www.google.com/favicon.ico", {
        method: "HEAD",
        mode: "no-cors"
      });
      // Si la solicitud es exitosa, hay conexión
      return !response.ok || response.type === "opaque";
    } catch (error) {
      // Si ocurre un error, no hay conexión
      return false;
    }
  };

  // Si hay un callback, usa los listeners de eventos
  if (callback) {
    const handleOnline = async () => {
      const isOnline = await verifyConnection();
      callback(isOnline);
    };
    const handleOffline = () => callback(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Ejecuta la verificación al cargar
    handleOnline();

    // Retorna una función de limpieza para eliminar los listeners cuando se desee
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }

  // Si no hay callback, devuelve el estado actual
  return await verifyConnection();
}
