import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import carExample from "../../assets/Odometer.svg";
import CameraModal from "../../components/camera_modal";
import PhotoOdometroMensual from "../../components/photo_odometro_mensual";
import StepsBar from "../../components/StepsBar";
import { useAuth } from "../../provider/AuthProvider";
import { usePoliza } from "../../provider/PolizaProvider";

//Funcion para DataLayer Google 
import Alerta from "../../components/Alerta";
import Modal from "../../components/Modal";
import { pushToDataLayer } from "../../lib/pushToDataLayer";

const ReporteOdometro = ({ textFooterLegal }) => {
  const { user } = useAuth();
  const { poliza, sendOdometerBase64, paymentDetails } = usePoliza();

  const [odometroPhoto, setOdometroPhoto] = useState(null);
  const [odometro, setOdometro] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [odometroPhotoModal, setOdometroPhotoModal] = useState(false);
  const [odometroModal, setOdometroModal] = useState(false);

  const [paso5, setPaso5] = useState(false);
  const [paso6, setPaso6] = useState(false);
  const [paso7, setPaso7] = useState(false);

  const navigate = useNavigate();

  const resetFotosOdometro = () => {
    setPaso5(false);
    setOdometro(null);
    setOdometroPhoto(null);
    setOdometroModal(false);
  };

  function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleConfirm = async () => {
    const loadingElement = document.getElementById('loading');
    loadingElement.classList.remove('hidden');
    const res_odometroPhoto = await sendOdometerBase64(
      poliza.policy.id,
      odometro,
      odometroPhoto.replace("data:image/png;base64,", ""),
      user.authToken
    );

    if (res_odometroPhoto.data?.status != "success") {
      resetFotosOdometro();
      setIsAlert(true)
      setAlertMessage("no se envio la foto de odometro ")
      return;
    }
    try {
      const res_paymentDetails = await paymentDetails(poliza.policy.id, odometro, poliza.client.client_id, poliza.policy.month + 1, user.authToken);
      if (res_paymentDetails.data?.confirm_odometer != true) {
        resetFotosOdometro();
        setIsAlert(true)
        setAlertMessage("no se envió la confirmación de odometro de odometro")
        return;
      }
      if (res_paymentDetails.data?.details?.amount > 0) {
        // loadingElement.classList.add('hidden');
        const eventParams = {
          event_category: 'Reporte mensual odometro',
          step: '2A Confirmacion de datos',
          click: 'Confirmar'
        }
        pushToDataLayer('clic_km_odometro_confirmar', eventParams)
        navigate('/pago_mensual')
      } else {
        // loadingElement.classList.add('hidden');
        navigate('/dashboard')
      }
    } catch (error) {
      if (error.response.status !== 200) {
        setIsAlert(true)
        setAlertMessage(error.response.data.Message || 'Lo sentimos, inténtelo más tarde.')
      }
    } finally {
      loadingElement.classList.add('hidden');

    }

    // if (res_paymentDetails.data?.confirm_odometer != true) {
    //   resetFotosOdometro();
    //   return;
    // }
    // if (res_paymentDetails.data?.details?.amount > 0) {
    //   loadingElement.classList.add('hidden');
    //   const eventParams = {
    //     event_category: 'Reporte mensual odometro',
    //     step: '2A Confirmacion de datos',
    //     click: 'Confirmar'
    //   }
    //   pushToDataLayer('clic_km_odometro_confirmar', eventParams)
    //   navigate('/pago_mensual')
    // } else {
    //   loadingElement.classList.add('hidden');
    //   navigate('/dashboard')
    // }
  };
  useEffect(() => {
    if (
      !(
        poliza.policy.hasOdometerPicture == true &&
        poliza.policy.reportState == 13
      )
    ) {
      navigate("/dashboard");
    }
  }, [])

  const steps = [
    {
      label: 'Tu odómetro',
      labelMobile: '<p class=\'text-left\'>Tu odómetro<p/>'
    },
    {
      label: 'Confirmación de datos',
      labelMobile: "<p class='text-center'>Confirmación <br>de datos<p/>"
    },
    {
      label: 'Recibo del mes',
      labelMobile: "<p class='text-right'>Recibo <br>del mes<p/>"
    }
  ]

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="space-y-6  px-4 mt-5">
      <Alerta isAlertaOpen={isAlert} setAlert={setIsAlert} isTextAlert={alertMessage} isIconClose={'error'} isTimeClose={3000} />
      {paso5 == false && (
        <div className="space-y-6">
          <div className="">
            <StepsBar steps={steps} actual={1} />
          </div>
          <p>
            Por favor, toma una fotografía de
            tu odómetro tal como se muestra en
            la imagen de ejemplo.
          </p>
          <div className="card">
            <div className="card-header">
              <p className="text-sm md:text-base font-bold">Puntos importantes a considerar</p>
            </div>
            <div>
              <div className='grid lg:grid-cols-1 gap-y-2 gap-x-6'>
                <div className='flex text-left lg:justify-left items-start gap-4'>
                  <div className='flex-shrink-0 bg-liverpoolLead h-[6px] w-[6px] mt-2 rounded-full' />
                  <div className='text-sm'>Asegúrate que la iluminación sea la adecuada para que la fotografía sea clara y visible.</div>
                </div>
                <div className='flex text-left lg:justify-left items-start gap-4'>
                  <div className='flex-shrink-0 bg-liverpoolLead h-[6px] w-[6px] mt-2 rounded-full' />
                  <div className='text-sm'>La fotografía deberá de mostrar el odómetro ya que servirá como estado actual del mismo.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card w-full my-4">

            <PhotoOdometroMensual
              carPhoto={carExample}
              poliza={poliza}
              onHandlePhotoButton={() => {
                const eventParams = {
                  event_category: 'Reporte mensual odometro',
                  step: '1 Tu odometro',
                  click: 'Tomar fotografia'
                }
                pushToDataLayer('clic_tomar_fotografia_odometro', eventParams)
                setOdometroPhotoModal(true)
              }} />
          </div>
        </div>
      )}

      {odometroPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Muestra la parte trasera de tu ID"}
          cancel={() => {
            setOdometroPhoto(null);
            setOdometroPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setOdometroPhoto(dataUri);
          }}
          photo={odometroPhoto}
          onHandleConfirmPhoto={() => {
            setOdometroPhotoModal(false);
            setPaso5(true);
            const eventParams = {
              event_category: 'Reporte mensual odometro',
              step: '1A Tu odometro fotografia',
              click: 'Siguiente'
            }
            pushToDataLayer('clic_fotografia_odometro_siguiente', eventParams)
          }}
          onHandleCancelPhoto={() => setOdometroPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {paso5 == true && paso6 == false && (
        <div className="space-y-6">
          <div className="">
            <StepsBar steps={steps} actual={2} />
          </div>
          <div className="card max-w-md w-full my-4  mx-auto space-y-4">

            {/* <PhotoOdometroMensual carPhoto={odometroPhoto} poliza={poliza} title={"Odometro"} onHandlePhotoButton={() => {
              if (odometro) {
                setOdometroModal(true);
              }
            }} titleButton="Subir Odometro" >
            </PhotoOdometroMensual> */}


            <PhotoOdometroMensual carPhoto={odometroPhoto} poliza={poliza} title={"Odometro"}   >
            </PhotoOdometroMensual>

            <input
              type="number"
              id="odometro"
              name="odometro"
              value={odometro || ""}
              onChange={(e) => setOdometro(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mx-auto w-full py-2 p-2 "
              placeholder="Introduce tu odómetro actual"
              required
            />
            <p className="text-xs">
              <span className="font-medium">Importante:</span> El odómetro declarado deberá corresponder a la foto de la unidad asegurada.
            </p>
          </div>
          <div className="max-w-md w-full my-4  mx-auto">
            <button
              className="background-color-liverpool w-full items-center px-4 py-2 border border-transparent rounded-md text-white hover:bg-liverpoolHover focus:bg-liverpoolHover active:bg-liverpoolHover focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 disabled:opacity-25"
              type="button"
              disabled={!odometro}
              onClick={() => {
                if (odometro && !/^[0-9]+$/.test(odometro)) {
                  setIsAlert(true)
                  setAlertMessage("Por favor escriba solo números.")
                } else {
                  const eventParams = {
                    event_category: 'Reporte mensual odometro',
                    step: '2 Tu odometro declarado',
                    click: 'Siguiente'
                  }
                  pushToDataLayer('clic_cargar_km_odometro_siguiente', eventParams)
                  setPaso6(true)
                  setPaso7(true)
                }
              }}
            >
              Siguiente
            </button>
          </div>
        </div>
      )}

      {
        paso7 && (
          <div className="space-y-6">
            <div className="">
              <StepsBar steps={steps} actual={2} />
            </div>
            <div className="card max-w-md w-full my-4  mx-auto space-y-4">
              <div className="card-header">
                <p className="text-sm md:text-base font-bold">Por favor, confirma los siguientes datos</p>
              </div>
              <div className="space-y-3">
                <p className="text-sm md:text-base">Tu odómetro actual es de:</p>
                <p className="text-center text-5xl text-liverpoolLead">{formatNumber(odometro)}</p>
                <p className="text-center text-base text-liverpoolLead">Kilómetros</p>
              </div>
            </div>
            <div className="space-y-6 max-w-md w-full my-4  mx-auto space-y-4">
              <button
                className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
                type="button"
                onClick={() => {
                  handleConfirm()
                }}
              >
                Confirmar
              </button>
              <button
                className="w-full mt-4 text-liverpoolPink rounded-md font-medium text-sm px-5 py-2.5 text-center bg-white border-liverpoolPink border-2"
                type="button"
                onClick={() => {
                  setPaso7(false)
                  setPaso5(true)
                  setPaso6(false)

                }}
              >
                Modificar
              </button>
            </div>
          </div>
        )
      }

      {/* antes se usaba este modal para pasar a la vista pagar */}
      {/* {odometroModal && (
        <ConfirmOdometerModal
          className={"z-20"}
          title={"Correo electrónico"}
          text={"¿Tú odometro es correcto?"}
          cancel={() => {
            setOdometro(null);
            setOdometroModal(false);
          }}
          odometro={odometro}
          onHandleConfirm={handleConfirm}
          confirmTitle="Confirmar"
        />
      )} */}
      <div className="text-center pb-4 mt-16">
        <button className="link text-xs" onClick={(event) => {
          event.preventDefault()
          openModal()
        }}>
          Consulta Términos legales
        </button>
      </div>
      {isModalOpen && (
        <Modal className="text-center" description={textFooterLegal} closeModal={closeModal} />
      )}
    </div>
  );
};

export default ReporteOdometro;
