import { useEffect } from 'react';

const Alerta = ({ isAlertaOpen, setAlert, isTextAlert, isIconClose, isTimeClose }) => {
  useEffect(() => {
    if (isAlertaOpen) {
      const timer = setTimeout(() => {
        // Lógica para ocultar la alerta
        // Aquí podrías pasar una función de cierre a través de props si es necesario
        // toggleAlerta();
        setAlert(false)
      }, isTimeClose);

      return () => clearTimeout(timer); // Limpiar el timeout al desmontar el componente
    }
  }, [isAlertaOpen, isTimeClose]);

  return (
    <>
      {isAlertaOpen && (
        <div className="fixed right-0 left-0 justify-center flex p-4 top-10 !z-[9999999]">
          <div
            className={`w-full md:w-3/5 shadow-lg ${isIconClose === 'success' ? 'bg-green-100' : 'bg-red-100'} border-l-4 ${isIconClose === 'success' ? 'border-green-500' : 'border-red-500'} px-4 py-3 rounded relative`}
            role="alert"
          >
            <div className="flex justify-between">
              <div className="flex items-center">
                <span>
                  {isIconClose === 'success' ? (
                    <svg
                      className={`fill-current h-6 w-6 text-green-500 mr-1`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2 10a8 8 0 1 1 16 0A8 8 0 0 1 2 10zm11.414 2.414L10 10.828l-3.414 3.586L5 13.414l3.414-3.586L5 6.828l1.414-1.414L10 8.172l3.414-3.586L15 6.828l-3.414 3.586L15 13.414z" />
                    </svg>
                  ) : (
                    <svg
                      className={`fill-current h-6 w-6 text-red-500 mr-1`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 6h2v5H9zm0 6h2v2H9z" />
                    </svg>
                  )}
                </span>
                <span className="block sm:inline text-carbon">
                  {isTextAlert}
                </span>
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Alerta;
