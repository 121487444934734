
const PhotoCard = ({carPhoto, title, subtitle, poliza, onHandlePhotoButton, titleButton='Tomar fotografía', children}) => {
    return (
        <div className="bg-white shadow-md rounded-lg max-w-md w-full my-4 py-4 mx-auto">
          {
            title && (
              <div className="w-full border-b px-4">
                <h2 className="text-sm font-semibold text-gray-800 mb-3">
                    {title}
                </h2>
              </div>    
            )
          }
          
          <div className="w-full px-4">
            {
              subtitle
            }
          </div>
          {
            children
          }
         
          <div className="w-full px-4">
            <p className=" text-sm text-gray-700 my-2">
            Imagen de ejemplo:
            </p>
          </div>
          <div className="mx-4 mt-4 overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl">
            <img
              src={carPhoto}
              alt="card-image"
              className="h-auto w-62 rounded-lg mx-auto"
            />
          </div>
          <div className="p-3 pt-0">
            <button
              className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
              type="button"
              onClick={onHandlePhotoButton}
            >
              {titleButton}
            </button>
          </div>
      </div>
    );

}

export default PhotoCard