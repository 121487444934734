import axios from "axios";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StepsBar from '../../components/StepsBar';
import { useAuth } from "../../provider/AuthProvider";
import { usePoliza } from "../../provider/PolizaProvider";

// Datalayer Google Tag Manager
import Modal from "../../components/Modal";
import { pushToDataLayer } from "../../lib/pushToDataLayer";

const PagoMensual = ({ textFooterLegal }) => {
  const { user } = useAuth();
  const { poliza } = usePoliza();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [polizaInfo, setPolizaInfo] = useState(null);
  const [pagoModal, setPagoModal] = useState(false);
  const [card, setCard] = useState(null)
  const [typeCard, setTypeCard] = useState(null);
  const [fechaExpira, setFechaExpira] = useState(null);
  const [nombreTarjeta, setNombreTarjeta] = useState(null);
  const [cvv, setCvv] = useState(null);

  const [listCards, setListCards] = useState([]);

  // progress bar
  // const [stepProgressBar, setStepProgressBar] = useState(1)

  function formatNumber(num) {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (poliza) {
      const loadingElement = document.getElementById('loading');
      loadingElement.classList.remove('hidden');
      getInfoPoliza();
      getPaymentInfo();
      getListCard();

    }
    else {
      navigate('/dashboard')
    }
  }, [poliza]);

  const getPaymentInfo = async () => {
    const loadingElement = document.getElementById('loading');
    const info_month_res = await axios.get(
      import.meta.env.VITE_BACK_URL + "/api/payment/info_month/" + poliza.policy.id,
      {
        headers: {
          Authorization: "Bearer " + user.authToken,
        },
      }
    );
    if (info_month_res.data.status == "success") {
      setPaymentDetails(info_month_res.data.result);
    }
    loadingElement.classList.add('hidden');
    return info_month_res;
  };

  const getListCard = async () => {
    const listCards_res = await axios.get(
      import.meta.env.VITE_BACK_URL + "/api/list/cards",
      {
        headers: {
          Authorization: "Bearer " + user.authToken,
        },
      }
    );
    if (listCards_res.data.status == "success") {
      setListCards(listCards_res.data.result);
    }
    return listCards_res;
  };

  const getInfoPoliza = async () => {

    const info_poliza_res = await axios.get(
      import.meta.env.VITE_BACK_URL + "/api/details/policy/info/" + poliza.policy.id,
      {
        headers: {
          Authorization: "Bearer " + user.authToken,
        },
      }
    );
    if (info_poliza_res.data.status == "success") {
      setPolizaInfo(info_poliza_res.data.result);
      const info_poliza = info_poliza_res.data.result;
      if (info_poliza.PlocyModel.Id_statusReporte !== 24) {
        navigate("/dashboard");
      }

    }
    return info_poliza_res;
  };

  // const handleChange = (e) =>{
  //   switch (e.target.name) {
  //       case 'card':
  //           setCard(e.target.value)
  //           break;
  //       case 'type_card':
  //           let tipo = JSON.parse(e.target.value);
  //           setTypeCard(tipo);
  //           break;
  //       case 'fecha_expira':
  //         setFechaExpira(e.target.value);
  //         break;
  //       case 'nombre_tarjeta':
  //         setNombreTarjeta(e.target.value);
  //         break;
  //       case 'cvv':
  //         setCvv(e.target.value);
  //         break;
  //       default:
  //           break;
  //   }
  // }

  // const handleConfirm = async () => {
  //   console.log('se envia');
  //   console.log('tipo tarjeta',typeCard)
  //   console.log('card', card);
  //   console.log('fecha_expira',fechaExpira);
  //   console.log('cvv', cvv);
  //   console.log('nombre_tarjet',nombreTarjeta)
  //   try {
  //     const res_payment = await axios.post(import.meta.env.VITE_BACK_URL+'/api/payment/'+poliza.policy.id,{
  //       card: card,
  //       cvv: cvv,
  //       fecha_expira: fechaExpira,
  //       mes: polizaInfo?.PlocyModel?.Mensualidad,
  //       nombre_tarjetahabiente: nombreTarjeta,
  //       tarjeta: {
  //         name: typeCard.name,
  //         type: typeCard.type
  //       }
  //     },{
  //       headers: {
  //         Authorization: "Bearer " + user.authToken,
  //       },
  //     });
  //     console.log(res_payment);
  //     if(res_payment.data?.status == 'success'){
  //       navigate('/dashboard');
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const handleCancel = () => {
  //   setCard(null)
  //   setTypeCard({})
  //   setFechaExpira(null);
  //   setCvv(null)
  //   setNombreTarjeta(null);
  //   setPagoModal(false);
  // }

  const steps = [
    {
      label: 'Tu odómetro',
      labelMobile: '<p class=\'text-left\'>Tu odómetro<p/>'
    },
    {
      label: 'Confirmación de datos',
      labelMobile: "<p class='text-center'>Confirmación <br>de datos<p/>"
    },
    {
      label: 'Recibo del mes',
      labelMobile: "<p class='text-right'>Recibo <br>del mes<p/>"
    }
  ]

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>

      <div className="space-y-6 container px-4 mt-5">

        <div className="">
          <StepsBar steps={steps} actual={3} />
        </div>
        <div className="card">
          <div className="card-header">
            <p className="text-sm md:text-base font-bold">Tu recibo del mes</p>
          </div>
          <div>
            <div className='text-xs space-y-2 text-carbon'>
              <div className=' space-y-4'>
                <div className=' grid grid-cols-3  items-center'>
                  <div className='col-span-2 md:col-span-2 justify-self-start'>
                    <p>Kilómetros recorridos en el mes:</p>
                  </div>
                  <div className='col-span-1 md:col-span-1 justify-self-end'>
                    <p className='xl:whitespace-nowrap text-right'>
                      {paymentDetails?.kilometro_cobrado}{' '}Kms.
                    </p>
                  </div>
                </div>

                <div className=' grid grid-cols-3 items-center'>
                  <div className='col-span-2 justify-self-start'>
                    <p>Kilómetros de promoción:</p>
                  </div>
                  <div className='col-span-1 justify-self-end'>
                    <p>
                      {paymentDetails?.kilometro_promocion}{' '}Kms.
                    </p>
                  </div>
                </div>

                <div className=' grid grid-cols-3  items-center'>
                  <div className='col-span-2 justify-self-start'>
                    <p>Prima por kilómetro:</p>
                  </div>
                  <div className='col-span-1 justify-self-end'>
                    <p className='xl:whitespace-nowrap text-right'>
                      ${paymentDetails?.tarifa}{' '}MXN
                    </p>
                  </div>
                </div>
                <div className='flex justify-between !mt-4'>
                  <p className='font-bold text-carbon'>Total a pagar:</p>
                  <p className='font-bold text-red-500'>
                    ${paymentDetails?.amount}{' '}MXN
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <p className="text-sm md:text-base font-bold">Tus odómetros registrados</p>
          </div>
          <div>
            <div className='text-xs space-y-2 text-carbon'>
              <div className=' space-y-4'>
                <div className=' grid grid-cols-3  items-center'>
                  <div className='col-span-2 md:col-span-2 justify-self-start'>
                    <p>Anterior:</p>
                  </div>
                  <div className='col-span-1 md:col-span-1 justify-self-end'>
                    <p className='xl:whitespace-nowrap text-right'>
                      {formatNumber(paymentDetails?.kilometro_anterior)}{' '}Kms.
                    </p>
                  </div>
                </div>

                <div className=' grid grid-cols-3 items-center'>
                  <div className='col-span-2 justify-self-start'>
                    <p className="text-carbon font-bold">Actual:</p>
                  </div>
                  <div className='col-span-1 justify-self-end'>
                    <p className="text-carbon font-bold">
                      {formatNumber(paymentDetails?.kilometro_actual)}{' '}Kms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button
            className="background-color-liverpool w-full items-center px-4 py-2 border border-transparent rounded-md text-white hover:bg-liverpoolHover focus:bg-liverpoolHover active:bg-liverpoolHover focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 disabled:opacity-25"
            type="button"
            disabled={!paymentDetails?.amount}
            onClick={() => {
              const eventParams = {
                event_category: 'Reporte mensual odometro',
                step: '3 Finalizar',
                click: 'Finalizar'
              }
              pushToDataLayer('clic_finalizar_reporte', eventParams)
              navigate('/info_pago_mensual')
            }}
          >
            Pagar
          </button>

        </div>

        <div className="p-6 hidden">
          <div className="flex items-center justify-between mb-2">
            <p className="block font-sans text-base font-medium tracking-tight text-blue-gray-900">
              {poliza.vehicle.description}
            </p>
          </div>
          <div className="flex items-center justify-between mb-2">
            <p className="block font-sans text-base antialiased font-medium tracking-tight text-blue-gray-900">
              {poliza.policy.policyNumber}
            </p>
          </div>
          <p className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 opacity-75">
            Fecha limite para pagar tu reporte:{" "}
            {moment.parseZone(poliza.policy.limitReportVehicle).format("LLLL")}
          </p>
          <p className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 opacity-75">
            Tu recibo del mes
          </p>
          <div className="flex w-full">
            <div className="grow h-14">
              Kilómetros recorridos en el mes:
            </div>
            <div className="grow-0 h-14">
              {paymentDetails?.kilometro_cobrado}
            </div>
          </div>
          <div className="flex w-full">
            <div className="grow h-14">
              Kilómetros de promoción:
            </div>
            <div className="grow-0 h-14">
              ${paymentDetails?.kilometro_promocion}
            </div>
          </div>
          <div className="flex w-full">
            <div className="grow h-14">
              Prima por kilómetro:
            </div>
            <div className="grow-0 h-14">
              ${paymentDetails?.tarifa}
            </div>
          </div>
          <div className="flex w-full">
            <div className="grow h-14">
              Total a pagar:
            </div>
            <div className="grow-0 h-14">
              ${paymentDetails?.amount}
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <p className="block font-sans text-base antialiased font-medium tracking-tight text-blue-gray-900">
              Tus odómetros registrados
            </p>
          </div>
          <div className="flex w-full">
            <div className="grow h-14">
              Anterior:
            </div>
            <div className="grow-0 h-14">
              {paymentDetails?.kilometro_anterior} Kms.
            </div>
          </div>
          <div className="flex w-full">
            <div className="grow h-14">
              Actual:
            </div>
            <div className="grow-0 h-14">
              {paymentDetails?.kilometro_actual} Kms.
            </div>
          </div>
        </div>
        <div className="p-6 pt-0 hidden">
          <button
            className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-pink-900/20 focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none block w-full focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            type="button"
            onClick={() => setPagoModal(true)}
          >
            Pagar
          </button>
        </div>
      </div>

      {/* {pagoModal && (
        <PagoModal 
          className={'z-20 '} 
          title={'Paga el reporte'} 
          card={card} 
          onHandleChange={handleChange} 
          listCards={listCards} 
          typeCard={typeCard} 
          fecha_expira={fechaExpira} 
          nombre_tarjeta={nombreTarjeta} 
          cvv={cvv} 
          onHandleConfirm={handleConfirm} 
          cancel={handleCancel} />
      )} */}
      <div className="text-center pb-4 mt-16">
        <button className="link text-xs" onClick={(event) => {
          event.preventDefault()
          openModal()
        }}>
          Consulta Términos legales
        </button>
      </div>
      {isModalOpen && (
        <Modal className="text-center" description={textFooterLegal} closeModal={closeModal} />
      )}
    </div>
  );
};

export default PagoMensual;
