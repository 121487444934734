import React from 'react';

const ImportantPointsCard = ({isOdometer}) => {
  return (
    <div className="bg-white shadow-md rounded-lg max-w-md w-full py-4 mx-auto">
        <div className="w-full border-b px-4">
            <h2 className="text-sm font-semibold text-gray-800 mb-3">
                Puntos importantes a considerar
            </h2>
        </div>
      <ul className=" space-y-2 pl-8 my-4 list-disc" >
        <li className="">
          <p className="text-sm text-liverpoolLead">
            {isOdometer ? 'Asegúrate que la iluminación sea la adecuada para que la fotografía sea clara y visible.'
            :  "Por favor, toma las fotografías siguiendo la imagen de ejemplo como referencia."}
          </p>
        </li>
        <li className="">
          <p className="text-sm text-liverpoolLead">
            {isOdometer ? 'La fotografía deberá de mostrar el odómetro ya que servirá como estado actual del mismo.' 
            : "Las placas, cristales y detalles de tu vehículo deben ser visibles."}
          </p>
        </li>
      </ul>
    </div>
  );
};

export default ImportantPointsCard;
