
export default function AvisoPrivacidad({ textAvisoPrivacidad }) {

  return (
    <main className='container mx-auto p-4 rounded-md shadow-md'>
      {textAvisoPrivacidad?.map((avisoPrivacidad, index) => (
        <div className='card' key={index}>
          <h2 className='font-bold text-liverpoolCarbon'>{avisoPrivacidad.titulo}</h2>
          <p className='!mt-2'>{avisoPrivacidad.contenido}</p>
        </div>
      ))}
    </main>
  )
}
