export default function detectDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Detectar si el dispositivo es móvil
  if (/android/i.test(userAgent)) {
    return 'mobile';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'mobile';
  }

  // Si no es móvil, se asume que es escritorio
  return 'desktop';
}

// Uso de la función
const deviceType = detectDeviceType();
console.log(`Estás usando un dispositivo: ${deviceType}`);