import 'moment/locale/es';
import moment from 'moment/moment';
import { useNavigate } from "react-router-dom";
import LateralIzquierdoWidescreen from '../assets/icon/LateralIzquierdoWidescreen.png';
import WarningIcon from '../assets/icon/Warning.png';
import { usePoliza } from "../provider/PolizaProvider";

const PolicyCard = ({ poliza, onHandleFotosIniciales, onHandleReporteOdometro, onHandlePagoMensual, onHandleAuditoria }) => {
  const { launchPoliza, removePoliza } = usePoliza();
  const navigate = useNavigate();
  
  return (
    <div
      className="relative flex flex-col mx-auto m-5 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-full"
    >
      {poliza.policy.hasOdometerPicture == false &&
        (poliza.policy.hasVehiclePictures == false || poliza.policy.hasVehiclePictures == true) &&
        poliza.policy.reportState == 13 &&
        (<div className="flex border-b px-2 py-4 m-0">
          <img src={WarningIcon} alt="Warning Icon" width={16} height={16} className="mx-2 object-contain h-4 w-4" />
          <h1 className="text-sm font-bold">
            Las fotos de tu auto aún no estan registradas
          </h1>
        </div>)
      }
      {poliza.policy.hasOdometerPicture == true &&
        poliza.policy.hasVehiclePictures == true &&
        poliza.policy.reportState == 13 && (<div className="flex border-b px-2 py-4 m-0">
          <img src={WarningIcon} alt="Warning Icon" width={16} height={16} className="mx-2" />
          <h1 className="text-sm font-bold">
            Es hora de reportar el odómetro de tu auto
          </h1>
        </div>)
      }
      {poliza.policy.reportState == 12 && (<p className="flex items-center border-b px-2 py-4 m-0">
        <p src={WarningIcon} alt="Póliza activa" width={16} height={16} className="mx-2 bg-green-500 h-3 w-3 rounded-xl"></p>
        <h1 className="text-sm font-bold">
          Tu póliza está activa
        </h1>
      </p>)
      }
      <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl">
        <img
          src={
            (poliza.policy.hasOdometerPicture == false &&
              poliza.policy.hasVehiclePictures == false &&
              poliza.policy.reportState == 13) ? (LateralIzquierdoWidescreen) : (
              poliza.policy.vehiclePictures.front
                ? poliza.policy.vehiclePictures.front
                : LateralIzquierdoWidescreen)
          }
          alt="card-image"
          className="h-auto w-full rounded-lg"
        />
      </div>
      <div className="p-6 pb-0">
        <div className="grid grid-cols-2 grid-flow-row gap-4 text-sm">
          <p>
            Número de póliza:
          </p>
          <p className='text-right'>
            {poliza.policy.policyNumber}
          </p>
          <p>
            Fecha límite para tu próximo reporte: 
          </p>
          <p className='text-right'>
            {moment.parseZone(poliza.policy.limitReportVehicle).format('D/MM/YYYY') + ' antes de las ' + moment.parseZone(poliza.policy.limitReportVehicle).format('HH:mm') + ' hrs.'}
          </p>
        </div>
        {poliza.policy.reportState == 12 && (
          <div className='border-t-2 w-full  mt-4'>
            <button className='pt-4 w-full flex items-center justify-between'
            onClick={() =>{
              removePoliza();
              launchPoliza(poliza);
              navigate('/informacion_poliza_activa');
            }}
            >
              <p className='text-lead text-sm' >Ver detalles de póliza</p>
              <svg
                viewBox="0 0 32 32"
                fill="none"
                height={16}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d={`M10.4393 5.43934C11.0251 4.85355 11.9749 4.85355 12.5607 5.43934L22.5607 15.4393C23.1464
                16.0251 23.1464 16.9749 22.5607 17.5607L12.5607 27.5607C11.9749 28.1464 11.0251 28.1464 10.4393
                27.5607C9.85355 26.9749 9.85355 26.0251 10.4393 25.4393L19.3787 16.5L10.4393 7.56066C9.85355
                6.97487 9.85355 6.02513 10.4393 5.43934Z`}
                  className="fill-current"
                />
              </svg>
            </button>

          </div>
        )}
      </div>
      <div className="p-6 pt-0">
        {poliza.policy.hasOdometerPicture == false &&
          poliza.policy.reportState == 13 && (
            <button
              className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
              type="button"
              onClick={() => {
                onHandleFotosIniciales();
              }}
            >
              Tomar fotografías
            </button>
          )}

        {poliza.policy.hasOdometerPicture == true &&
          poliza.policy.hasVehiclePictures == true &&
          poliza.policy.reportState == 13 && (
            <button
              className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
              type="button"
              onClick={() => {
                onHandleReporteOdometro();
              }}
            >
              Tomar fotografía
            </button>
          )}

        {poliza.policy.hasOdometerPicture == true &&
          poliza.policy.hasVehiclePictures == true &&
          poliza.policy.reportState == 24 && (
            <button
              className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
              type="button"
              onClick={() => {
                onHandlePagoMensual();
              }}
            >
              Pagar mensualidad
            </button>
          )}

        {
          poliza.policy.reportState == 21 && (
            <button
              className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
              type="button"
              onClick={() => {
                onHandleAuditoria();
              }}
            >
              Auditar Fotos
            </button>
          )
        }
      </div>
    </div>
  );
};

export default PolicyCard;
