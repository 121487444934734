export const pushToDataLayer = (event = '', eventParams): void => {
  // Verifica si el primer parámetro es una cadena vacía y asigna el valor predeterminado si es necesario
  const eventName = event || 'seguro_km_app';

  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: eventName,
    event_params: eventParams
  })
}
