import { useEffect, useState } from 'react'

const ProgressBar = ({ steps, actual }: any) => {
  const [width, setWidth] = useState(0)
  // state para saber la resolucion de la pantalla y mostrar el texto version web o mobile
  const [isGreaterThan768px, setIsGreaterThan768px] = useState(window.innerWidth > 768)

  const handleResize = () => {
    setIsGreaterThan768px(window.innerWidth > 768)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const delta = 100 / (steps.length - 1)
    const decimalWidth = delta * (actual - 1)
    // console.log(delta)
    // console.log(decimalWidth)
    setWidth(decimalWidth)
  }, [actual])

  return (
    <div className='pt-4 pb-10 md:pb-5 px-2 bg-white rounded shadow-md lg:pt-0
      lg:px-0 lg:bg-transparent lg:shadow-none '>
      <div className="mb-2 md:mb-4">

        <div className="flex justify-between relative w-full z-[1] ">
          {steps.map((step: any, i: number) => {
            return (
              <StepItem
                key={i}
                completado={actual >= i + 1}
                inicial={i === 0}
                ultimo={i === steps.length - 1}
              >
                {

                  isGreaterThan768px
                    ? <>
                      {

                        typeof step.label === 'string'
                          ? (
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: step.label ? step.label : ''
                              }}
                            />
                          )
                          : (
                            step.label
                          )
                      }
                    </>
                    : <>
                      {

                        typeof step.labelMobile === 'string'
                          ? (
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: step.labelMobile ? step.labelMobile : ''
                              }}
                            />
                          )
                          : (
                            step.labelMobile
                          )
                      }
                    </>
                }
              </StepItem>
            )
          })}
          {/* Barra gris */}
          <div className="absolute top-1/2 -translate-y-1/2 left-1 w-[calc(100%-8px)] h-1 bg-gray-300 z-[-1]">
            {/* Barra rosa (progreso) */}
            <div
              style={{
                width: `${width}%`
              }}
              className="h-full bg-pink-500"
            />
            {/* End of Barra rosa (progreso) */}
          </div>
          {/* End of Barra gris */}
        </div>
      </div>
    </div>
  )
}

export default ProgressBar

const StepItem = ({ children, completado, inicial, ultimo }: any) => {
  const className = `h-5 w-5 flex rounded-full items-center justify-center text-center ${completado ? 'bg-pink-500' : 'bg-gray-300'
    }`

  return (
    <div
      className={`relative flex flex-col items-center ${inicial && '!items-start'
        } ${ultimo && '!items-end'}
    `}
    >
      <div className={className} />

      <p className={completado
        ? 'top-full absolute block text-center w-max  text-xs lg:text-xs xl:text-sm mt-1 text-carbon !whitespace-pre-line md:whitespace-normal '
        : 'top-full absolute block text-center w-max  text-xs lg:text-xs xl:text-sm mt-1 text-timberwolf !whitespace-pre-line md:whitespace-normal '}>
        {children}
      </p>
    </div>
  )
}
