import { useContext, createContext, useState } from "react";

const ResumePagoContext = createContext();

const ResumePagoProvider = ({ children }) => {
    const [ resumePago, setResumePago ] = useState(JSON.parse(localStorage.getItem('resume_pago')) || null);

    const launchResumePago = (data) => {
        localStorage.setItem('resume_pago',JSON.stringify(data));
        setResumePago(data);
    }

    const removeResumePago = () => {
        setResumePago(null);
        localStorage.removeItem('resume_pago');
    }

    return (
        <ResumePagoContext.Provider value={{resumePago, launchResumePago, removeResumePago}}>
            {children}
        </ResumePagoContext.Provider>
    );
}

export default ResumePagoProvider;

export const useResumePago = () => {
    return useContext(ResumePagoContext);
}