import axios from "axios";
import { useContext, createContext, useState } from "react";

const PolizaContext = createContext();

const PolizaProvider = ({ children }) => {
    const [poliza, setPoliza] = useState(JSON.parse(localStorage.getItem('poliza')) || null);

    const launchPoliza = (poliza) => {
        localStorage.setItem('poliza',JSON.stringify(poliza));
        setPoliza(poliza);
    }

    const removePoliza = () => {
        setPoliza(null);
        localStorage.removeItem('poliza');
    }

    const sendImageBase64 = async (poliza_id, tipo,base64, authToken) => {
        const response = await axios.post(import.meta.env.VITE_BACK_URL+'/api/vehicle_picture/base64/'+poliza_id,{
            type:tipo,
            image: base64
          },{
            headers: {
              Authorization: "Bearer " + authToken,
            },
          });
        return response;
    } 

    const sendOdometerBase64 = async (poliza_id, odometro, base64, authToken) => {
        const response = await axios.post(import.meta.env.VITE_BACK_URL+'/api/odometer_picture/base64/'+poliza_id,{
            odometer:odometro,
            image: base64
          },{
            headers: {
              Authorization: "Bearer " + authToken,
            },
          });
        return response;
    }

    const confirmOdometer = async (poliza_id, odometro, client_id, client_token, authToken) => {
        const response = await axios.post(import.meta.env.VITE_BACK_URL+'/api/confirm_odometer/'+poliza_id,{
            odometer:odometro,
            client_id: client_id,
            client_token: client_token
          },{
            headers: {
              Authorization: "Bearer " + authToken,
            },
          });
        return response;
    }

    const paymentDetails = async (poliza_id, odometro, client_id, mes, authToken) => {

      const response = await axios.post(import.meta.env.VITE_BACK_URL+'/api/payment/details/'+poliza_id,{
        client_id: client_id,
        odometer: odometro,
        month: mes
      },{
        headers:{
          Authorization: "Bearer " + authToken,
        }
      });
      return response;
    }

    const downloadPoliza = async (poliza_id, authToken) => {
      try {
        const response = await axios.get(import.meta.env.VITE_BACK_URL+'/api/details/policy/'+poliza_id,{
          responseType: 'blob',
          headers:{
            Authorization: "Bearer " + authToken,
            'Content-Type': 'application/pdf',
          }
        });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      } catch (error) {
        console.error('Error descargando el PDF:', error);
      }
    }

    return (
        <PolizaContext.Provider value={{poliza, launchPoliza, removePoliza, sendImageBase64, sendOdometerBase64, confirmOdometer, paymentDetails, downloadPoliza}}>
            {children}
        </PolizaContext.Provider>
    );
}

export default PolizaProvider;

export const usePoliza = () => {
    return useContext(PolizaContext);
}