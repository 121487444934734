import React from 'react';

const ProgressBar = ({ currentStep = 1 }) => {
  return (
    <div className="bg-white shadow-md p-4 w-full max-w-md mx-auto">
      <div className="flex items-center justify-between mx-2">
        {/* Paso 1 */}
        <div className="flex flex-col items-center">
          <div
            className={`w-4 h-4 rounded-full flex items-center justify-center ${
              currentStep >= 1 ? 'bg-pink-500' : 'bg-gray-300'
            }`}
          >
          </div>
        </div>

        {/* Línea entre Paso 1 y Paso 2 */}
        <div className={`flex-grow mx-0 ${currentStep >= 2 ? 'bg-pink-500' : 'bg-gray-300'} h-1 ${currentStep >= 2 ? 'bg-gray-300' : 'bg-gray-200'}`} />

        {/* Paso 2 */}
        <div className="flex flex-col items-center">
          <div
            className={`w-4 h-4 rounded-full flex items-center justify-center ${
              currentStep >= 2 ? 'bg-pink-500' : 'bg-gray-300'
            }`}
          >
          </div>
          
        </div>

        {/* Línea entre Paso 2 y Paso 3 */}
        <div className={`flex-grow  mx-0 ${currentStep >= 3 ? 'bg-pink-500' : 'bg-gray-300'} h-1 ${currentStep >= 3 ? 'bg-gray-300' : 'bg-gray-200'}`} />

        {/* Paso 3 */}
        <div className="flex flex-col items-center">
          <div
            className={`w-4 h-4 rounded-full flex items-center justify-center ${
              currentStep >= 3 ? 'bg-pink-500' : 'bg-gray-300'
            }`}
          >
          </div>
          
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-center w-20">
            <span className={`mt-2 text-xs font-medium text-gray-700 text-left ${currentStep > 1 && '!text-gray-200'}`}>
              Carga de fotografías
            </span>
        </div>
        <div className="flex flex-col items-center w-20">
            <span className={`mt-2 text-xs font-medium text-gray-700 text-center  ${currentStep < 2 && '!text-gray-300'}`}>
              Tu odómetro
            </span>
        </div>
        <div className="flex flex-col items-center w-20">
            <span className={`mt-2 text-xs font-medium text-gray-700 text-right  ${currentStep <= 3 && '!text-gray-300'}`}>
              Confirmación de datos
            </span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
