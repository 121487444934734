import rowLeft from "../assets/icon/row_left2x.png";

const Navbar = ({ title = '', returnUrl = true }) => {
  return (
    <>
      <nav className="bg-liverpoolPink px-6">
        <div className="flex items-center space-y-0 relative py-5 lg:py-10">
          <button
            onClick={() => window.history.length >= 1 ? window.history.back() : window.location.href = import.meta.env.VITE_HOME_LIVERPOOL}
            className="flex items-center "
          >
            {returnUrl && (
              <img
                src={rowLeft}
                className="h-4"
                alt="Flowbite Logo"
              />
            )}
          </button>
          <div className="w-full text-center relative left-0">
            <span className="self-center text-lg font-medium  text-white">
              {title ? title : "Seguro de Auto Pago por Kilómetro"}
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
