import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import rightCarExample from "../../assets/car_example.png";
import backCarExample from '../../assets/icon/back.png';
import frontCarExample from '../../assets/icon/front.png';
import leftCarExample from '../../assets/icon/left.png';
import OdometerIcon from '../../assets/icon/Odometer.png';
import odometerCarExample from '../../assets/icon/OdometerCar.png';
import seguroAutoLiver from '../../assets/seguro_auto_liverpool.png';
import Alerta from "../../components/Alerta";
import CameraModal from "../../components/camera_modal";
import ImportantPointsCard from "../../components/important_points_card";
import Modal from "../../components/Modal";
import PhotoCard from "../../components/photo_card";
import { formatNumber } from "../../hooks/formatNumber";
import { useAuth } from "../../provider/AuthProvider";
import { usePoliza } from "../../provider/PolizaProvider";

const Auditoria = () => {
  const { user } = useAuth();
  const { poliza, sendImageBase64, sendOdometerBase64, confirmOdometer } = usePoliza();
  const navigate = useNavigate();

  const [toAuditar, setToAuditar] = useState([]);
  const [toShow, setToShow] = useState("");
  const [index, setIndex] = useState(0);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [frontalPhoto, setFrontalPhoto] = useState(null);
  const [izquierdaPhoto, setIzquierdaPhoto] = useState(null);
  const [derechaPhoto, setDerechaPhoto] = useState(null);
  const [traseraPhoto, setTraseraPhoto] = useState(null);
  const [odometroPhoto, setOdometroPhoto] = useState(null);
  const [odometro, setOdometro] = useState(null);
  const [thankyouPage, setThankyouPage] = useState(false);

  const [frontalPhotoModal, setFrontalPhotoModal] = useState(false);
  const [izquierdaPhotoModal, setIzquierdaPhotoModal] = useState(false);
  const [derechaPhotoModal, setDerechaPhotoModal] = useState(false);
  const [traseraPhotoModal, setTraseraPhotoModal] = useState(false);
  const [odometroPhotoModal, setOdometroPhotoModal] = useState(false);
  const [odometroModal, setOdometroModal] = useState(false);
  const [confirmTotalOdometer, setConfirmTotalOdometer] = useState(false);

  useEffect(() => {
    if (!(poliza.policy.reportState == 21)) {
      navigate("/dashboard");
    } else {
      infoAuditoria();
    }
  }, []);
  useEffect(() => {
    if (toAuditar.length > 0) {
      setToShow(toAuditar[index]);
    }
  }, [toAuditar]);

  const infoAuditoria = async () => {
    const loadingElement = document.getElementById('loading');
    try {
      loadingElement.classList.remove('hidden');
      const info_auditoria_res = await axios.post(
        import.meta.env.VITE_BACK_URL + "/api/audit/info/" + poliza.policy.id,
        {
          client_token: poliza.client.client_token,
        },
        {
          headers: {
            Authorization: "Bearer " + user.authToken,
          },
        }
      );
      if (info_auditoria_res.data?.status == "success") {
        let result = info_auditoria_res.data?.result;
        result = result.sort((a, b) => a.Id - b.Id);
        setToAuditar(result);
      }
    } catch (error) {
      navigate("/dashboard");
    } finally {
      loadingElement.classList.add('hidden');
    }
  };

  const sendPhotos = async () => {
    const loadingElement = document.getElementById('loading');
    for (const [index, element] of toAuditar.entries()) {
      loadingElement.classList.remove('hidden');
      if (element.Id !== 5) {
        let res_photo = await sendPhoto(element.Id);
        if (res_photo?.data?.status !== 'success') {
          loadingElement.classList.add('hidden');
          setIsAlert(true)
          setAlertMessage('no se envio la foto ' + element.Descripcion)
          return;
        }
        if (index == toAuditar.length - 1) {
          let res_update = await axios.post(import.meta.env.VITE_BACK_URL + '/api/audit/update_report/' + poliza.policy.id, {
            client_id: poliza?.client?.client_id
          }, {
            headers: {
              Authorization: "Bearer " + user.authToken,
            },
          });
          loadingElement.classList.add('hidden');
          if (res_update?.data?.status !== 'success') {
            setAlertMessage('no se ejecuto actualización de fotos auditadas')
            setIsAlert(true)

            return;
          }
          else {
            navigate('/dashboard');
          }
        }
      }
      else {
        const res_odometroPhoto = await sendOdometerBase64(
          poliza.policy.id,
          odometro,
          odometroPhoto.replace("data:image/png;base64,", ""),
          user.authToken
        );
        if (res_odometroPhoto.data?.status != "success") {
          setAlertMessage('no se envio la foto de odometro')
          setIsAlert(true)
          return;
        }
        const res_odometroConfirm = await confirmOdometer(
          poliza.policy.id,
          odometro,
          poliza.client.client_id,
          poliza.client.client_token,
          user.authToken
        );
        if (res_odometroConfirm.data?.status != "success") {
          setAlertMessage('no se envio la foto de odometro')
          setIsAlert(true)
          return;
        }
        navigate('/dashboard');
      }
    }
    setThankyouPage(true)
  }

  const sendPhoto = async (type) => {
    let res;
    switch (type) {
      case 1:
        res = await sendImageBase64(
          poliza.policy.id,
          "front",
          frontalPhoto.replace("data:image/png;base64,", ""),
          user.authToken
        );
        break;
      case 2:
        res = await sendImageBase64(
          poliza.policy.id,
          "right",
          derechaPhoto.replace("data:image/png;base64,", ""),
          user.authToken
        );
        break;
      case 3:
        res = await sendImageBase64(
          poliza.policy.id,
          "back",
          traseraPhoto.replace("data:image/png;base64,", ""),
          user.authToken
        );
        break;
      case 4:
        res = await sendImageBase64(
          poliza.policy.id,
          "left",
          izquierdaPhoto.replace("data:image/png;base64,", ""),
          user.authToken
        );
        break;
      default:
        break;
    }
    return res;
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="container p-4">
      <Alerta isAlertaOpen={isAlert} setAlert={setIsAlert} isTextAlert={alertMessage} isIconClose={'error'} isTimeClose={3000} />

      {!odometroPhoto &&
        <p className="mb-4">
          {'A continuación te solicitaremos la fotografía en la que detectamos inconsistencias. Toma en cuenta las siguientes recomendaciones:'}
        </p>
      }

      {!odometroPhoto && <ImportantPointsCard isOdometer={(toShow?.Id === 5)} />}
      {toShow?.Id == 1 && (
        <PhotoCard carPhoto={frontCarExample} title={'1.- Fotografía frontal'} poliza={poliza} onHandlePhotoButton={() => setFrontalPhotoModal(true)} />
      )}

      {frontalPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Fotografía frontal"}
          cancel={() => {
            setFrontalPhoto(null);
            setFrontalPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setFrontalPhoto(dataUri);
          }}
          photo={frontalPhoto}
          onHandleConfirmPhoto={() => {
            setFrontalPhotoModal(false);
            if (index + 1 <= toAuditar.length - 1) {
              setIndex(index + 1);
              setToShow(toAuditar[index + 1]);
            } else {
              sendPhotos();
            }
          }}
          onHandleCancelPhoto={() => setFrontalPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {toShow?.Id == 2 && (
        <PhotoCard carPhoto={leftCarExample} title={'2.- Fotografía lateral izquierda'} poliza={poliza} onHandlePhotoButton={() => setDerechaPhotoModal(true)} />
      )}

      {derechaPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Fotografía lateral izquierda"}
          cancel={() => {
            setDerechaPhoto(null);
            setDerechaPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setDerechaPhoto(dataUri);
          }}
          photo={derechaPhoto}
          onHandleConfirmPhoto={() => {
            setDerechaPhotoModal(false);
            if (index + 1 <= toAuditar.length - 1) {
              setIndex(index + 1);
              setToShow(toAuditar[index + 1]);
            } else {
              sendPhotos();
            }
          }}
          onHandleCancelPhoto={() => setDerechaPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {toShow?.Id == 3 && (
        <PhotoCard carPhoto={backCarExample} title={'3.- Fotografía trasera'} poliza={poliza} onHandlePhotoButton={() => setTraseraPhotoModal(true)} />
      )}

      {traseraPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Fotografía trasera"}
          cancel={() => {
            setTraseraPhoto(null);
            setTraseraPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setTraseraPhoto(dataUri);
          }}
          photo={traseraPhoto}
          onHandleConfirmPhoto={() => {
            setTraseraPhotoModal(false);
            if (index + 1 <= toAuditar.length - 1) {
              setIndex(index + 1);
              setToShow(toAuditar[index + 1]);
            } else {
              setIndex(index + 1);
              setToShow(toAuditar[index + 1]);
              sendPhotos();
            }
          }}
          onHandleCancelPhoto={() => setTraseraPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {toShow?.Id == 4 && (
        <PhotoCard carPhoto={rightCarExample} title={'4.- Fotografía lateral derecha'} poliza={poliza} onHandlePhotoButton={() => setIzquierdaPhotoModal(true)} />
      )}

      {izquierdaPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Fotografía lateral derecha"}
          cancel={() => {
            setIzquierdaPhoto(null);
            setIzquierdaPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setIzquierdaPhoto(dataUri);
          }}
          photo={izquierdaPhoto}
          onHandleConfirmPhoto={() => {
            setIzquierdaPhotoModal(false);
            if (index + 1 <= toAuditar.length - 1) {
              setIndex(index + 1);
              setToShow(toAuditar[index + 1]);
            } else {
              sendPhotos();

            }
          }}
          onHandleCancelPhoto={() => setIzquierdaPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {((toShow?.Id == 5 && odometroPhoto == null) || (toShow?.Id == 5 && odometroPhotoModal == true)) && (
        <PhotoCard carPhoto={odometerCarExample} title={'Subir foto del odometro'} poliza={poliza} onHandlePhotoButton={() => setOdometroPhotoModal(true)} />
      )}

      {odometroPhotoModal && (
        <CameraModal
          className={"z-20"}
          title={"Subir foto del odometro"}
          cancel={() => {
            setOdometroPhoto(null);
            setOdometroPhotoModal(false);
          }}
          onHandleTakePhoto={(dataUri) => {
            setOdometroPhoto(dataUri);
          }}
          photo={odometroPhoto}
          onHandleConfirmPhoto={() => {
            setOdometroPhotoModal(false);
          }}
          onHandleCancelPhoto={() => setOdometroPhoto(null)}
          confirmTitle="Confirmar"
        />
      )}

      {(toShow?.Id == 5 && odometroPhoto !== null && odometroPhotoModal == false && !confirmTotalOdometer) && (

        <>
          <div className="px-4 pt-4 card space-y-4">
            <img
              src={odometroPhoto}
              alt="card-image"
              className="h-auto w-62 rounded-lg mx-auto"
            />
            <input
              type="number"
              id="odometro"
              name="odometro"
              value={odometro || ""}
              autoFocus
              onChange={(e) => setOdometro(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Introduce tu odómetro actual"
              required
            />
            <p className="text-liverpoolLead text-xs"> <strong>Importante:</strong> El odómetro declarado deberá corresponder a la foto de la unidad asegurada.</p>
          </div>
          <button
            className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
            type="button"
            onClick={() => {
              if (odometro) {
                setOdometroModal(true);
                setConfirmTotalOdometer(true);
              }
            }}
          >
            Siguiente
          </button>
        </>
      )
      }


      {odometroModal && (
        <>
          <div className="bg-white shadow-md rounded-lg max-w-md w-full py-4 mx-auto">
            <div className="w-full border-b px-4 mb-4">
              <h2 className="text-sm font-semibold text-gray-800 pb-2">
                Por favor, confirma los siguientes datos
              </h2>
            </div>
            <div className="px-4">
              <p className="mb-4">
                Tu odómetro actual es de:
              </p>
              <p className="text-4xl text-center">{formatNumber(Number(odometro)) || ''}</p>
              <p className="text-center">Kilómetros</p>
            </div>
          </div>
          <div>
            <button
              className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
              type="button"
              onClick={() => {
                setOdometroModal(false);
                sendPhotos()
              }}
            >
              Confirmar
            </button>
            <button
              className="w-full mt-4 text-liverpoolPink rounded-md border-liverpoolPink border-solid border-2 font-medium text-sm px-5 py-2.5 text-center bg-white"
              type="button"
              onClick={() => {
                setOdometro(null);
                setOdometroModal(false);
                setConfirmTotalOdometer(false)
                // setOdometroPhotoModal(false);
              }}
            >
              Modificar

            </button>
          </div>
        </>

      )}

      {thankyouPage === true && (
        <>
          <div className="bg-white flex items-center justify-between p-4">
            <div className="w-full">
              <img src={seguroAutoLiver} alt="Logo de seguro auto Liverpool" width={130} height={35} className="h-[35px] w-[130px]" />
            </div>
            <div>
              <p className="font-bold text-liverpoolPink text-lg">Javier</p>
              <p className="text-liverpoolCarbon"><strong>Agradecemos tu confianza.</strong> <br />
                La fotografía se registró con éxito.</p>
            </div>
          </div>
          <div className="mt-8">
            <div className="bg-white">
              <div className="border-b p-4">
                <p className="text-liverpoolCarbon font-bold">Información importante</p>
              </div>
              <div className="p-4" >
                <div className="flex items-start justify-between space-x-4">
                  <img className="h-auto" src={OdometerIcon} alt="Icono de odometro" height={20} width={30} />
                  <div className="text-xs">
                    <p className="font-bold">No olvides reportar tu odómetro</p>
                    <p>Recuerda enviar una foto de tu odómetro cada mes para saber los kilómetros que recorriste y procedas a realizar el pago mensual correspondiente.</p>
                  </div>
                </div>
                <p className="text-xs mt-4">Ponemos a tu disposición un centro especializado de atención a clientes,
                  en caso de que tengas alguna duda comunícate al <a className="font-bold" href="tel: 800 200 0019">800 200 0019</a> .</p>
              </div>
            </div>
            {/* <div className="card mt-6">
              <div className="flex items-center justify-between">
                <a href="#" >Descarga tu póliza</a>
                <img src={downLoad} alt="Icono de descarga" />
              </div>
            </div> */}
            <div className="mt-6">
              <button className="w-full mt-4 text-white rounded-md font-medium text-sm px-5 py-2.5 text-center bg-liverpoolPink"
                onClick={() => { navigate("/dashboard") }}>
                Terminar
              </button>
            </div>
          </div>
        </>
      )}
      <div className="text-center pb-4 mt-16">
        <button className="link text-xs" onClick={(event) => {
          event.preventDefault()
          openModal()
        }}>
          Consulta Términos legales
        </button>
      </div>
      {isModalOpen && (
        <Modal className="text-center" description={textFooterLegal} closeModal={closeModal} />
      )}
    </div >
  );
};

export default Auditoria;
