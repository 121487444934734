
import 'moment/locale/es';
import moment from 'moment/moment';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LateralIzquierdoWidescreen from '../../assets/icon/LateralIzquierdoWidescreen.png';
import PremiumIcon from '../../assets/icon/Premium.svg';
import Alerta from "../../components/Alerta";
import { useAuth } from "../../provider/AuthProvider";
import { usePoliza } from "../../provider/PolizaProvider";

import Modal from "../../components/Modal";

const InfoPolizaActiva = ({ textFooterLegal }) => {
  const { user } = useAuth();
  const { poliza, downloadPoliza } = usePoliza();
  const navigate = useNavigate();

  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    console.log("user", user)
    console.log("poliza", poliza)
    console.log("navigate", navigate)
  }, []);

  const handleViewpoliza = async () => {
    const loadingElement = document.getElementById('loading');
    loadingElement.classList.remove('hidden');
    // document.body.style.overflow = 'hidden';
    downloadPoliza(poliza.policy.id, user.authToken)
      .then((resDP) => {
        loadingElement.classList.add('hidden');
        document.body.style.overflow = '';
      })
      .catch((error) => {
        loadingElement.classList.add('hidden');
        // document.body.style.overflow = '';
        // console.error('Hubo un error al obtener la política:', error)
        setAlertMessage(error)
        setIsAlert(true)
      })

  }

  return (
    <div className="container space-y-8">
      <Alerta isAlertaOpen={isAlert} setAlert={setIsAlert} isTextAlert={alertMessage} isIconClose={'error'} isTimeClose={3000} />

      <div className="card">
        <p className="text-carbon text-xl text-center font-bold">¡Hola {user.name}!</p>
      </div>
      <div className="px-4">
        <div className="relative flex flex-col mx-auto m-5 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-full space-y-3">
          <div>
            <div className="flex border-b px-2 py-4 m-0 items-center">
              <img src={PremiumIcon} alt="Warning Icon" width={24} height={24} className="mx-2" />
              <h1 className="text-sm font-bold">
                Estos son los datos de tu póliza
              </h1>
            </div>
            <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl">
              <img
                src={LateralIzquierdoWidescreen}
                alt="card-image"
                className="h-auto w-full rounded-lg"
              />
            </div>
          </div>

          <div className=" space-y-3  text-sm px-4">
            <div className=' grid grid-cols-3  items-center'>
              <div className='col-span-2 md:col-span-2 justify-self-start'>
                <p>Número de póliza:</p>
              </div>
              <div className='col-span-1 md:col-span-1 justify-self-end'>
                <p className='xl:whitespace-nowrap text-right'>
                  {poliza.policy.policyNumber}
                </p>
              </div>
            </div>
            <div className=' grid grid-cols-3  items-center'>
              <div className='col-span-2 md:col-span-2 justify-self-start'>
                <p>Conductor:</p>
              </div>
              <div className='col-span-1 md:col-span-1 justify-self-end'>
                <p className='xl:whitespace-nowrap text-right'>
                  {poliza.client.name + ' ' + poliza.client.last_name}
                </p>
              </div>
            </div>
            <div className=' grid grid-cols-3  items-center'>
              <div className='col-span-2 md:col-span-2 justify-self-start'>
                <p>Tu vehículo:</p>
              </div>
              <div className='col-span-1 md:col-span-1 justify-self-end'>
                <p className='xl:whitespace-nowrap text-right'>
                  {poliza.vehicle.description}
                </p>
              </div>
            </div>
            <div className=' grid grid-cols-3  items-center'>
              <div className='col-span-2 md:col-span-2 justify-self-start'>
                <p>Placas:</p>
              </div>
              <div className='col-span-1 md:col-span-1 justify-self-end'>
                <p className='xl:whitespace-nowrap text-right'>
                  {poliza.vehicle.plates}
                </p>
              </div>
            </div>
            <div className=' grid grid-cols-3  items-center'>
              <div className='col-span-2 md:col-span-2 justify-self-start'>
                <p>Modelo:</p>
              </div>
              <div className='col-span-1 md:col-span-1 justify-self-end'>
                <p className='xl:whitespace-nowrap text-right'>
                  {poliza.vehicle.model}
                </p>
              </div>
            </div>
            <div className=' grid grid-cols-3  items-center'>
              <div className='col-span-2 md:col-span-2 justify-self-start'>
                <p>Último reporte de odómetro:</p>
              </div>
              <div className='col-span-1 md:col-span-1 justify-self-end'>
                <p className='xl:whitespace-nowrap text-right'>
                  {moment.parseZone(poliza.policy.odometerDate).format('D/MM/YYYY')}
                </p>
              </div>
            </div>
            <div className='border-t-2 w-full  py-4 '>
              <div>

                <button className=' w-full flex items-center justify-between'
                  onClick={
                    handleViewpoliza}
                >
                  <p >Ver póliza</p>
                  <svg
                    viewBox="0 0 32 32"
                    fill="none"
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d={`M10.4393 5.43934C11.0251 4.85355 11.9749 4.85355 12.5607 5.43934L22.5607 15.4393C23.1464
                        16.0251 23.1464 16.9749 22.5607 17.5607L12.5607 27.5607C11.9749 28.1464 11.0251 28.1464 10.4393
                        27.5607C9.85355 26.9749 9.85355 26.0251 10.4393 25.4393L19.3787 16.5L10.4393 7.56066C9.85355
                        6.97487 9.85355 6.02513 10.4393 5.43934Z`}
                      className="fill-current"
                    />
                  </svg>
                </button>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div className="text-center pb-4 mt-16">
        <button className="link text-xs" onClick={(event) => {
          event.preventDefault()
          openModal()
        }}>
          Consulta Términos legales
        </button>
      </div>
      {isModalOpen && (
        <Modal description={textFooterLegal} closeModal={closeModal} />
      )}

    </div >
  );
};

export default InfoPolizaActiva