import React from 'react'
import IconCancel from './IconCancel'
import footer_sponsers from '/footer_sponsors.png'
export default function Modal({ title = '', description = '', closeModal, className, isFooter = true }) {
  return (
    <div className={`${className} fixed inset-0 z-50 flex items-end justify-center  bg-black bg-opacity-50 !mt-0`}>
      <div className="bg-white rounded-lg shadow-lg w-full container p-4">
        <div className="text-right">
          {title && <h2>{title}</h2>}
          <button
            onClick={(event) => {
              event.preventDefault()
              closeModal()
            }}
            className="p-2 text-white"
          >
            <IconCancel />
          </button>
        </div>
        {isFooter && (
          <div className='pb-4'>
            <img className='mx-auto' src={footer_sponsers} alt="Logo de los patrocinadores" height={200} width={300} />
          </div>
        )}
        <p className="mt-2 text-liverpoolLead text-xs">
          {typeof description === "string" ? (description) : (
            description.map((item, index) => (
              <p key={index}>{item}</p>
            ))
          )}
        </p>
      </div>
    </div>
  )
}
