import React from 'react'

export default function IconCancel() {
  return (
    <svg
      className="cursor-pointer"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1667 2.47831L15.5217 0.833313L9 7.35498L2.47833 0.833313L0.833332 2.47831L7.355 8.99998L0.833332 15.5216L2.47833 17.1666L9 10.645L15.5217 17.1666L17.1667 15.5216L10.645 8.99998L17.1667 2.47831Z"
        fill="#ADA8A8"
      />
    </svg>
  )
}
